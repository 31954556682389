import React from "react"
import styled from 'styled-components'

//Helpers
import Title from "../helpers/Title"

//Components
import Layout from "../components/Layout"
import Seo from "../components/seo"

const Captura = () => (
  <Layout>
    <Seo title="Inscrições Webinar" />
    <Container>
      <Title>Inscrição da Aula como investir no mercado de jogos </Title>
      <Content>
        {/* <Form action="https://gamejamplus.us1.list-manage.com/subscribe/post?u=7ec78c1b269f8ef9120876738&amp;id=e7125b394a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <Input type="text" name="FNAME" id="mce-FNAME" placeholder="Primeiro Nome"required/>
          <Input type="email" name="EMAIL" id="mce-EMAIL" placeholder="E-mail" required/>
          <Button type='submit' value="Inscrever-se!" />
        </Form> */}
        <Text>Infelizmente as inscrições do nosso Webinar já estão encerradas.</Text>
      </Content>

    </Container>
  </Layout >
)

export default Captura

const Container = styled.section`
  padding-top: 2rem;
  margin-bottom: 4rem;
`

const Content = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (max-width: 768px) {

  }
`
const Text = styled.p`
  font-family: var(--primaryLight);
  font-size: 1.25rem;
  color: var(--pink);
  margin-bottom: 2rem;
  text-align: center;
`

// const Form = styled.form`
//   width: 60%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin: 4rem auto;

//   @media screen and (max-width: 768px) {
//     width: 100%;
//     max-width: 320px;
//     margin: 2rem auto;
//   }
// `

// const Input = styled.input`
//   width: 100%;
//   margin-bottom: 1.5rem;
//   padding: .5rem;
//   border: none;
//   background: #EEE;
//   font-family: var(--regular);
//   font-size: 1rem;
// `

// const Button = styled.input`
//   background: var(--lightPink);
//   padding: .5rem 1.5rem;
//   border: none;
//   font-size: 1rem;
//   color: var(--white);
//   cursor: pointer;
//   transition: all .3s;

//   &:hover {
//     transform: scale(1.1);
//   }
// `